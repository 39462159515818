import React from 'react';

const JustImage = ({ text, src }) => {

  const titulo = text? text : "A black hole ";
    
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70vh',
        width: '100%',
        textAlign: 'center',
        marginTop: '150px',
      }}
    >
      <img
        src={src}
        alt={titulo}
        title={titulo}
        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
      />
      {text && <h1 style={{ marginTop: '10px' }}>{text}</h1>}
    </div>
  );
};

export default JustImage;
