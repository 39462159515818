import React, { useEffect, useMemo } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import './NavigationBar.css';
import { useTheme } from '../themeContext';
import { useTranslation } from 'react-i18next';

import esFlag from './flags/es.png';
import enFlag from './flags/en.png';

const NavigationBar = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { theme, toggleTheme } = useTheme();

  // 1. Leer el lang de la URL y del localStorage
  const searchLang = new URLSearchParams(location.search).get('lang');
  const storedLang = localStorage.getItem('preferredLang');

  // 2. Determinar el "currentLang" según prioridad: URL > localStorage > "es"
  const currentLang = useMemo(() => {
    return searchLang || storedLang || 'en';
  }, [searchLang, storedLang]);

  // 3. Cambiar el idioma de i18n en el montaje o cuando cambie currentLang
  useEffect(() => {
    i18n.changeLanguage(currentLang);
  }, [currentLang, i18n]);

  // 4. Función para cambiar de idioma desde el botón
  const changeLanguage = () => {
    // Si estamos en 'es', cambiar a 'en', y viceversa
    const newLang = currentLang === 'es' ? 'en' : 'es';

    // Cambiar el idioma en i18n y guardar en localStorage
    i18n.changeLanguage(newLang);
    localStorage.setItem('preferredLang', newLang);

    // Cambiar el searchParam 'lang' en la URL
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('lang', newLang);

    // Navegar a la misma ruta con la nueva query, reemplazando la anterior
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  };

  return (
    <nav className={`navigation-bar ${theme}`}>
      <NavLink to="/" className="path blink">
        {`root@kali:${location.pathname === '/' ? "~" : location.pathname + "/"}#`}$
      </NavLink>

      <div className="nav-links">
        <NavLink to="/" end>
          Home
        </NavLink>
        <NavLink to="/about-me">{t("about_me")}</NavLink>
        <NavLink to="/posts">Posts</NavLink>
        <NavLink to="/projects">{t("projects")}</NavLink>

        <button onClick={toggleTheme} className="theme-toggle">
          {theme === 'dark' ? '🌕' : '🌑'}
        </button>

        <button
          onClick={changeLanguage}
          className="language-toggle"
          style={{ border: 'none', display: 'block', left: '0px', backgroundColor: 'transparent' }}
        >
          <img
            src={currentLang === 'es' ? enFlag : esFlag}
            alt={currentLang === 'es' ? 'English' : 'Español'}
            className="flag-icon"
            width="20px"
          />
        </button>
      </div>
    </nav>
  );
};

export default NavigationBar;
