import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { ThemeProvider } from './themeContext';
import NavigationBar from './components/NavigationBar';
import Home from './pages/home/Home';
import Error404 from './pages/404/404';
import Post from "./pages/post/Post";
import Posts from "./pages/posts/Posts";
import About from "./pages/about/About";
import JustImage from "./components/JustImage";
import Projects from "./pages/projects/Projects";
import RedirectM from "./components/redirect";
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react"
import './i18n';
import 'katex/dist/katex.min.css';
import { useTranslation } from 'react-i18next';
import './App.css';
const AppWrapper = () => {
  const location = useLocation();
  const { i18n } = useTranslation();
  const searchParams = new URLSearchParams(location.search);
  const langFromUrl = searchParams.get('lang');
  const storedLang = localStorage.getItem('preferredLang') || 'en'; 

  const lang = langFromUrl || storedLang;

  useEffect(() => {
    if (langFromUrl && langFromUrl !== storedLang) {
      i18n.changeLanguage(langFromUrl)
      localStorage.setItem('preferredLang', langFromUrl);
    }
  }, [langFromUrl, storedLang, i18n]);

  return (
    <>
      <NavigationBar lang={lang} />
      <Routes>
        {/* Homepage */}
        <Route path="/" element={<Home lang={lang} />} />
        <Route path="/about-me" element={<About lang={lang} />} />
        
        {/* Posts */}
        <Route path="/posts" element={<Posts type="posts" lang={lang} />} />
        <Route path="/posts/:slug" element={<Post type="posts" lang={lang} />} />
        <Route path="/cheats/:slug" element={<Post type="cheats" lang={lang} />} />

        <Route path="/projects" element={<Projects type="projects" lang={lang} />} />
        <Route path="/projects/:slug" element={<Post type="projects" lang={lang} />} />

        {/* Social redirects */}
        <Route path="/github" element={<RedirectM url={"https://github.com/M3str3"} />} />
        
        <Route path="/twitter" element={<RedirectM url={"https://x.com/0xRootKit"} />} />
        <Route path="/x" element={<RedirectM url={"https://x.com/0xRootKit"} />} />

        {/* Files */}
        <Route path="/pgp" element={<RedirectM url={"/m3str3.asc"} />} />
        
        {/* Memes */}
        <Route path="/wp-admin" element={<JustImage text={"A donde coño vas XD"} src={"/media/imgs/wp-admin.png"} />} />
        <Route path="/admin" element={<JustImage text={"A donde coño vas XD"} src={"/media/imgs/wp-admin.png"} />} />
        <Route path="/administrator" element={<JustImage text={"A donde coño vas XD"} src={"/media/imgs/wp-admin.png"} />} />


        {/* 404 */}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

function App() {
  const homepage = process.env.PUBLIC_URL;

  return (
    <ThemeProvider>
      <Router basename={homepage ? homepage : "/"}>
        <AppWrapper />
      </Router>
      <Analytics />
      <SpeedInsights />
    </ThemeProvider>
  );
}

export default App;
