import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import posts_data from '../../content.json';
import { useTheme } from '../../themeContext';
import './Projects.css';
import { t } from 'i18next';

const groupPostsByYear = (posts) =>
  posts.reduce((groups, post) => {
    const year = new Date(post.date).getFullYear();
    if (!groups[year]) {
      groups[year] = [];
    }
    groups[year].push(post);
    return groups;
  }, {});

const Projects = ({ type, lang }) => {
  const { theme } = useTheme();

  const today = useMemo(() => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }, []);

  const filteredPosts = posts_data[type].filter(
    post =>
      (post.lang === lang || post.lang === 'global') &&
      (post.active === undefined || post.active === true) &&
      new Date(post.date) <= today
  );

  const groupedPosts = groupPostsByYear(filteredPosts);

  return (
    <>
      <div className={`bg ${theme}`}></div>
      <div className={`content ${theme}`}>
        <div className="posts container">
          <h1 style={{ textTransform: 'capitalize', textAlign: 'center' }}>{type}</h1>
          {Object.keys(groupedPosts).length > 0 ? (
            <div className="year-group">
              {Object.keys(groupedPosts)
                .sort((a, b) => b - a)
                .map(year => (
                  <div key={year} className="year-group-item">
                    <h2 className="year-title">{year}</h2>
                    {groupedPosts[year].map(post => (
                      <div key={post.slug} className="project-card">
                        <Link to={`/${type}/${post.slug}`}>
                          {post.image && (
                            <div className="project-image">
                              <img src={post.image} alt={post.title} />
                              <div className="project-date-badge">
                                {String(new Date(post.date).getDate()).padStart(2, '0')}/{String(new Date(post.date).getMonth() + 1).padStart(2, '0')}
                              </div>
                            </div>
                          )}
                          <div className="project-content">
                            <h3 className="project-title">{post.title}</h3>
                            <p className="project-description">{post.description}</p>
                            {post.categories && post.categories.length > 0 && (
                              <small className="project-categories">
                                {t('categories')}: {post.categories.join(', ')}
                              </small>
                            )}
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          ) : (
            <div className="no-projects">{t("no_posts")}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default Projects;
