import React, { useEffect } from 'react';
import { FaTwitter, FaGithub, FaLinkedin } from 'react-icons/fa';
import './Home.css';
import { useTheme } from '../../themeContext';
import { useTranslation } from 'react-i18next';

export default function Home() {
  const { t } = useTranslation();
  const { theme } = useTheme();

  // Función que realiza el efecto "scramble" de texto
  function scrambleText(el, newText, oldText = '') {
    const chars = '!<>-_\\/[]{}—=+*^?#________';

    // Genera un carácter aleatorio de la lista 'chars'
    const randomChar = () =>
      chars[Math.floor(Math.random() * chars.length)];

    return new Promise((resolve) => {
      const length = Math.max(oldText.length, newText.length);
      let frame = 0;

      // Creamos una "cola" para cada índice del texto
      const queue = Array.from({ length }).map((_, i) => {
        const from = oldText[i] || '';
        const to = newText[i] || '';
        const start = Math.floor(Math.random() * 40);
        const end = start + Math.floor(Math.random() * 40);
        return { from, to, start, end, char: null };
      });

      // Función que actualiza el texto en cada frame
      function update() {
        let output = '';
        let complete = 0;

        for (let i = 0; i < queue.length; i++) {
          const { from, to, start, end } = queue[i];
          let { char } = queue[i];

          if (frame >= end) {
            // Ya completamos este carácter
            complete++;
            output += to;
          } else if (frame >= start) {
            // Mezclamos caracteres aleatorios hasta llegar al final
            if (!char || Math.random() < 0.28) {
              char = randomChar();
              queue[i].char = char;
            }
            output += `<span class="dud">${char}</span>`;
          } else {
            // Aún no toca cambiar este índice
            output += from;
          }
        }

        el.innerHTML = output;

        // Si hemos completado todos los caracteres, resolvemos la promesa.
        if (complete === queue.length) {
          resolve();
        } else {
          frame++;
          requestAnimationFrame(update);
        }
      }

      // Iniciamos el loop
      requestAnimationFrame(update);
    });
  }

  useEffect(() => {
    const phrases = t("home.text").split(","); // Texto separado por comas
    const el = document.querySelector('.text-change');
    if (!el) return;

    let isCancelled = false;
    let counter = 0;

    const nextPhrase = () => {
      if (isCancelled || !phrases.length) return;
      // currentText: el texto “viejo” es el que actualmente se ve en pantalla
      const currentText = el.innerText || '';
      scrambleText(el, phrases[counter], currentText).then(() => {
        setTimeout(() => {
          if (!isCancelled) {
            counter = (counter + 1) % phrases.length;
            nextPhrase();
          }
        }, 2000);
      });
    };

    nextPhrase();

    // Cuando el efecto se limpie (cambio de componente o idioma otra vez),
    // marcamos el flag para no seguir ejecutando el bucle.
    return () => { isCancelled = true; };
  }, [t]); 
  // Se vuelve a ejecutar cuando cambie `t("home.text")`, permitiendo reanimar con frases nuevas.

  return (
    <div className={`bg ${theme}`} style={{ width: '100%', height: '100%', position: 'fixed' }}>
      <div className={`content home ${theme}`}>
        <div className="home-content">
          <img
            src="https://avatars.githubusercontent.com/u/62236987"
            alt="Logo"
            className="logo"
          />
          <h1>M3str3</h1>
          <div className="text-change-container">
            <div className="text-change"></div>
          </div>
          <div className="social-links">
            <a href="https://twitter.com/0xrootkit" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
            <a href="https://github.com/m3str3" target="_blank" rel="noopener noreferrer">
              <FaGithub />
            </a>
            <a
              href="https://www.linkedin.com/in/ignacio-josé-mestre-villagrasa-b79493183"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
